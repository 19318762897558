import { Component, ReactNode } from "react";
import { ImageSlider } from "../components";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

interface IProps { }
interface IState { }

export class Home extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render(): ReactNode {

        return (
            <>
                <ImageSlider />
                <div className="container mt-4 p-4">
                    <div className="row">
                        <h1>DIEGO TATTOO
                            <img src="https://corsoprofessionalediegotattoo.com/assets/logo1.png" style={{ marginTop: -4, marginLeft: 10, width: 60 }} alt="" />
                        </h1>

                        <p>presenta</p>
                        <h4>Corso professionale di tatuaggi</h4>
                        <h6>
                            Anche tu potrai diventare un tatuatore frequentando
                            il corso professionale messo a disposizione da Diego Tattoo.
                        </h6>
                        <h5><i>
                            “segreti e conoscenze di questa arte“
                        </i></h5>
                        <h6>
                            Condividerò con te i
                            segreti e le conoscenze di questa arte che ho
                            accumulato con la mia esperienza dal 1993,
                            permettendo così l'emergere di nuovi tattoo
                            artist.
                        </h6>
                        <h6>
                            Il metodo di questo corso prevede lo svolgimento individuale,
                            consentendoti di concentrarti al meglio nella tua privacy,
                            a differenza dei corsi di gruppo.
                        </h6>
                    </div>
                </div>

                <div className="container p-4 mb-3" style={{width:'90%'}}>
                    <div className="row">
                        <button className="btn btn-lg btn-custom"
                            onClick={() => window.location.href = "/courseDetail?id=1"}
                        >
                            VAI AL VIDEO CORSO
                        </button>
                    </div>
                </div>

                <div className="container 4-laterale mb-4">
                    <div className="row">
                        <div className="col-6 d-flex justify-content-center">
                            <FaFacebook size={40}
                                onClick={() => window.open('https://www.facebook.com/tattoodiegodarkillusion?locale=it_IT')}
                            />
                        </div>
                        <div className="col-6 d-flex justify-content-center">
                            <FaInstagram size={40}
                                onClick={() => window.open('https://www.instagram.com/diegotattooontheroad?igsh=MWcwZWd3bDMzenI3')}
                            />            
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
