import { Component, ContextType, ReactNode } from "react";
import 'react-slideshow-image/dist/styles.css';
import { Lezione } from "../dto/Lezione";
import { LoaderContext } from "../context";
import axios, { AxiosError } from "axios";
import { endpoint } from "../helpers";
import { notification } from "antd-notifications-messages";
import ReactPlayer from 'react-player'

interface IProps {
    lezione: Lezione
}

interface IState {
    carrello: boolean
}

export class CardLezione extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            carrello: false
        };

        this.addCart = this.addCart.bind(this);
        this.removeCart = this.removeCart.bind(this);
        this.dispatch = this.dispatch.bind(this);
    }

    context!: ContextType<typeof LoaderContext>

    
    componentDidMount(): void {
        const { lezione } = this.props;
        this.setState({ carrello: lezione.carrello })
    }

    addCart() {

        const { toggleLoader } = this.context;
        const { lezione } = this.props;

        if (!lezione)
            return;

        toggleLoader(true)
        axios.get(`${endpoint}/api/Carrello/Aggiungi?IdLezione=${lezione?.id}`, { withCredentials: true })
            .then(() => {
                this.setState({ carrello: true })
                notification({ message: 'Aggiunto al carrello', type: 'success' });
            }).catch((error: AxiosError<string>) => {
                if (error.response?.status === 401) {
                    window.location.href = `/Login?returnUrl=lessonDetail?id=${lezione?.id}`
                } else {
                    notification({ message: error.response?.data ?? 'Errore', type: 'error' })
                }
            }).finally(() => {
                toggleLoader(false)
            })
    }

    removeCart() {

        const { toggleLoader } = this.context;
        const { lezione } = this.props;

        if (!lezione)
            return;

        toggleLoader(true)
        axios.get(`${endpoint}/api/Carrello/Rimuovi?IdLezione=${lezione?.id}`, { withCredentials: true })
            .then(() => {
                this.setState({ carrello: false })
                notification({ message: 'Rimosso dal carrello', type: 'success' });
            }).catch((error: AxiosError<string>) => {
                if (error.response?.status === 401) {
                    window.location.href = `/Login?returnUrl=lessonDetail?id=${lezione?.id}`
                } else {
                    notification({ message: error.response?.data ?? 'Errore', type: 'error' })
                }
            }).finally(() => {
                toggleLoader(false)
            })
    }

    dispatch() {
        const { carrello } = this.state;

        if (carrello)
            this.removeCart();
        else
            this.addCart();
    }

    render(): ReactNode {

        const { lezione } = this.props;
        const { carrello } = this.state
        const videoPath = `${endpoint}/api/Fruizione/Get?videoCorsoId=1&lezioneId=${lezione?.id}`

        return (
            <>
                <div onContextMenu={e => e.preventDefault()} className="card-lezione">
                    <div onClick={() => window.location.href = `/lessonDetail?id=${lezione.id}`}>
                        <div className="row">
                            <div className="col-12">
                                {lezione?.acquistato ? (
                                    <ReactPlayer
                                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                        url={videoPath}
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                    />
                                ) : (
                                    <div className="border-image-card" style={{ width: '100%', height: '200px', backgroundImage: `url('${lezione?.previewImgUrl}')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }} />
                                )}
                            </div>
                        </div>
                        <div className="p-2 pb-0">
                            <div className="row color-red-4">
                                <h4>{lezione.titolo}</h4>
                            </div>
                            <div className="row color-red-4">
                                <div className="col-10">
                                    <h5>{lezione.descrizione}</h5>
                                </div>
                            </div>
                            {!lezione.acquistato && (
                                <div className="row">
                                    <div className="col-10">
                                        <h5>Durata {lezione.durataMinuti} min.</h5>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {!lezione.acquistato ? (
                        <div className="row p-2">
                            <div className="col-4">
                                <h4> {lezione.prezzo}€</h4>
                            </div>
                            <div className="col-8">
                                <button className="btn btn-custom w-100 p-0" style={{ minHeight: 30, fontSize: 10, zIndex: 2 }}
                                    onClick={this.dispatch}>
                                    {carrello ? 'RIMUOVI DAL CARRELLO' : 'AGGIUNGI AL CARRELLO'}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="row p-2">
                            <div className="col-12">
                                <h5>Acquistato il {lezione.acquistatoData}</h5>
                            </div>
                        </div>
                    )}
                </div>
            </>
        )
    }
}

CardLezione.contextType = LoaderContext
