import { Component, ReactNode } from "react"
import { LoaderProvider, UserProvider } from "./context"
import { AppContent } from "./components/index"

export class App extends Component {

  render(): ReactNode {
    return (
      <div id="App" className="vh-100">
        <div className="container main">

          <LoaderProvider>
            <UserProvider>
              <AppContent > 
              </AppContent >
            </UserProvider>
          </LoaderProvider>
        </div>
      </div>
    )
  }
}
