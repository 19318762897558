import ReactDOM from 'react-dom/client'

import { App } from './App'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd-notifications-messages/lib/styles/style.css';
import 'react-slideshow-image/dist/styles.css'
import './main.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(<App />)