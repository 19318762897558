import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function App() {

  const SetCookie = () => {
    Cookies.set("accepted-cookie", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9", {
      expires: 7,
    });
  };

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const handleClose = () => {
    SetCookie();
    setShow(false);
  }

  const GetCookie = () => {
    alert(Cookies.get("accepted-cookie"));
  };

  const RemoveCookie = () => {
    Cookies.remove("accepted-cookie");
  };

  useEffect(() => {
    const cookie = Cookies.get("accepted-cookie");
    if (!cookie)
      handleShow();
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title><p style={{ color: 'black' }}>Informativa sui Cookie </p></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: 'black' }}>
            Cliccando "Accetta", acconsenti all'uso dei cookie su questo sito.
            Puoi anche scegliere che vhe vengano usati solamente i cookie necessari per il funzionamento del sito</p>
        </Modal.Body>
        <Modal.Footer>
          <div className='row w-100'>
            <div className='col-5 d-flex justify-content-start'>      
                <button className='btn btn-sm btn-custom h-100'
                  onClick={handleClose}>
                  Accetta solo necessari
                </button>   
            </div>     
            <div className='col-2'></div>
            <div className='col-5 d-flex justify-content-end'>
                <button className='btn btn-sm btn-custom h-100'
                  onClick={handleClose}>
                  Accetta tutti i cookie
                </button>
              </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default App;
