import { Component, ContextType, ReactNode } from "react";
import { Lezione } from "../dto/Lezione";
import { LoaderContext } from "../context";
import { VideoCorso } from "../dto/VideoCorso";

interface IProps {
    lezione: Lezione | null
    videoCorso: VideoCorso | null
    removeChartFunc: any
}

interface IState {

}

export class CarrelloElemento extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            carrello: false
        };
    }

    context!: ContextType<typeof LoaderContext>



    render(): ReactNode {

        const { lezione, videoCorso } = this.props;

        const videoCorsoLezione = videoCorso ? true : false
        const id = videoCorso ? videoCorso.id : lezione?.id ?? 0
        const titolo = videoCorso ? videoCorso.titolo : lezione?.titolo
        const prezzo = videoCorso ? videoCorso.prezzo : lezione?.prezzo
        const previewImgUrl = videoCorso ? videoCorso.previewImgUrl : lezione?.previewImgUrl

        return (
            <>
                <div className="">
                    <div className="row">
                        <div className="col-4">
                            <div style={{ width: '100%', height: '50px', backgroundImage: `url('${previewImgUrl}')`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover' }} />
                        </div>
                        <div className="col-4">
                            <div className="color-red-4">
                                <h5>{titolo}</h5>
                            </div>
                            <h5>{prezzo}€</h5>
                        </div>
                        <div className="col-4">
                            <button className="btn btn-custom w-100 p-0 mt-2" style={{ minHeight: 30, fontSize: 10, zIndex: 2 }}
                                onClick={() => this.props.removeChartFunc(videoCorsoLezione, id)}>
                                {'RIMUOVI'}
                            </button>

                        </div>
                    </div>
                    <div className="separator"></div>
                </div>
            </>
        )
    }
}

CarrelloElemento.contextType = LoaderContext
