export interface LoaderDefaultType {
    isLoading: boolean
    message?: string | null
    percentage: number
    toggleLoader: any
}

export const LoaderDefaultValue: LoaderDefaultType = {
    isLoading: false,
    toggleLoader: () => { },
    percentage: 0
}