import { notification } from "antd-notifications-messages";
import { ChangeEvent, Component, ContextType, ReactNode } from "react";
import { Form } from "react-bootstrap";
import { LoaderContext } from "../context";
import axios, { AxiosError } from "axios";
import { endpoint } from "../helpers";
import { ResetPassword } from "../dto/ResetPassword";

interface IProps { }
interface IState {
    userId : string
    code : string
    password: string
    confermaPassword: string
}

export class PasswordSetNew extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            userId: '',
            code : '',
            password: '',
            confermaPassword: '',
        };

        this.conferma = this.conferma.bind(this)
    }

    context!: ContextType<typeof LoaderContext>
    
    componentDidMount(): void {
        const searchParams = new URLSearchParams(window.location.search)
        const code = searchParams.get('code');
        const userId = searchParams.get('userid');

        if (!code || !userId)
            window.location.href = '/'
        else
            this.setState({code : code, userId: userId})
    }

    conferma() {

        const { userId, code, password, confermaPassword } = this.state;
        const { toggleLoader } = this.context

        if (!password) {
            notification({ message: 'Inserire password', type: 'error' });
            return;
        }

        else if ((password !== confermaPassword)) {
            notification({ message: 'Le due password non concidono', type: 'error' });
            return;
        }

        const model : ResetPassword = {
            userId : userId,
            code: code,
            password : password
        }


        toggleLoader(true)
        axios.post(`${endpoint}/api/Auth/ChangePassword`, model , { withCredentials: true })
            .then(() => {
                notification({ message: 'La password è stata cambiata con successo', type: 'success' })
                setTimeout(() => { window.location.href = './home' }, 1000);
                window.location.href = '/'
            }).catch((error: AxiosError<string>) => {
                notification({ message: error.response?.data ?? 'Errore', type: 'error' })
            }).finally(() => {
                toggleLoader(false)
            })

    }

    render(): ReactNode {

        return (
            <div className="container p-4 d-flex justify-content-center">
                <div className="box-semi-trasparent center-custom">
                    <h2>Nuova password</h2>

                    <div className="row d-flex justify-content-center mt-5">
                        <h5>Inserisci la tua nuova password</h5>
                    </div>


                    <div className="row d-flex justify-content-center">
                        <div className="mt-3">
                            <p className="p-0 m-0 ">Password</p>
                            <Form.Control
                                type="password"
                                style={{ width: 270, height: 40 }}
                                onChange={(e: ChangeEvent<any>) => this.setState({ password: e.target.value })}
                            />
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <div className="mt-3">
                            <p className="p-0 m-0 ">Conferma password</p>
                            <Form.Control
                                type="password"
                                style={{ width: 270, height: 40 }}
                                onChange={(e: ChangeEvent<any>) => this.setState({ confermaPassword: e.target.value })}
                            />
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center mb-4">
                        <button className="btn btn-custom mt-4" style={{ width: 270, height: 40 }}
                            onClick={this.conferma}
                        >
                            CONFERMA
                        </button>
                    </div>
                </div>
            </div>
        )
    }
} 

PasswordSetNew.contextType = LoaderContext