import { ChangeEvent, Component, ContextType, ReactNode } from "react";
import { LoaderContext } from "../context";
import axios, { AxiosError } from "axios";
import { endpoint } from "../helpers";
import { notification } from "antd-notifications-messages";
import { ReportVendideDto } from "../dto/ReportVendideDto";
import { FaUserFriends, FaUserPlus } from "react-icons/fa"
import { Button, Form } from "react-bootstrap";
import { PaginationIndex } from "../components/generics/PaginationIndex";
import { FaEye } from "react-icons/fa";
import { ModalDatiFatturazione } from "../components/ModalDatiFatturazione";
import { DatiFatturazioneDto } from "../dto/DatiFatturazione";
import { format } from "date-fns";

interface IProps { }

interface IState {
    data: ReportVendideDto[]
    datiFatturazione : DatiFatturazioneDto | null
    searchString: string,
    currentPage: number,
    itemsPerPage: number,
    modalVisible : boolean
}

export class ReportVendite extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            data: [],
            datiFatturazione : null,
            searchString: '',
            currentPage: 1,
            itemsPerPage: 15,
            modalVisible : false
        };


        this.filterData = this.filterData.bind(this);
        this.setFatturato = this.setFatturato.bind(this);
        this.setNonFatturato = this.setNonFatturato.bind(this);
        this.dispatch = this.dispatch.bind(this);
        this.setCurrentPageByNum = this.setCurrentPageByNum.bind(this);
        this.getdata = this.getdata.bind(this);
    }

    context!: ContextType<typeof LoaderContext>

    componentDidMount(): void {
        this.getdata();
    }


    getdata() {
        const { toggleLoader } = this.context;

        toggleLoader(true)
        axios.get<ReportVendideDto[]>(`${endpoint}/api/Report/ReportVendite`, { withCredentials: true })
            .then(({ data }) => {
                this.setState({ data: data })
            }).catch((error: AxiosError<string>) => {
                notification({ message: error.response?.data ?? 'Errore', type: 'error' })
            }).finally(() => {
                toggleLoader(false)
            })
    }


    filterData() {
        const { data, searchString } = this.state;

        return data.filter(vendite =>
            vendite.dataVendita?.toLowerCase().includes(searchString.toLowerCase()) ||
            vendite.descrizioneVendita?.toLowerCase().includes(searchString.toLowerCase()) ||
            vendite.userEmail?.toLowerCase().includes(searchString.toLowerCase())
        );
    }

    setCurrentPageByNum(value: number): void {
        const { currentPage, itemsPerPage } = this.state
        const indexOfLastItem = currentPage * itemsPerPage;
        if (value > 0 && value <= indexOfLastItem)
            this.setState({ currentPage: value });
    }

    setFatturato(id: number) {
        const { toggleLoader } = this.context;

        toggleLoader(true)
        axios.get(`${endpoint}/api/Report/SetFatturato/${id}`, { withCredentials: true })
            .then(() => {
                this.getdata();
            }).catch((error: AxiosError<string>) => {
                notification({ message: error.response?.data ?? 'Errore', type: 'error' })
            }).finally(() => {
                toggleLoader(false)
            })
    }

    setNonFatturato(id: number) {
        const { toggleLoader } = this.context;

        toggleLoader(true)
        axios.get(`${endpoint}/api/Report/SetNonFatturato/${id}`, { withCredentials: true })
            .then(() => {
                this.getdata();
            }).catch((error: AxiosError<string>) => {
                notification({ message: error.response?.data ?? 'Errore', type: 'error' })
            }).finally(() => {
                toggleLoader(false)
            })
    }

    dispatch(id: number) {
        const { data } = this.state

        const fatturato = data.find(x => x.id === id)?.fatturato;

        if (fatturato === true)
            this.setNonFatturato(id);
        else
            this.setFatturato(id);
    }

    openModal(id : number) {
        const { data } = this.state
        this.setState({ datiFatturazione : data.find(x => x.id === id )?.datiFatturazione ?? null , modalVisible : true})
    }

    closeModal() {
        this.setState({ modalVisible: false })
    }

    render(): ReactNode {

        const { datiFatturazione, searchString, currentPage, itemsPerPage,modalVisible } = this.state
        const filteredData = this.filterData();
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const paginatedFilteredData: ReportVendideDto[] = filteredData.slice(indexOfFirstItem, indexOfLastItem);
        const totalPage = filteredData.length / itemsPerPage;

        return (
            <div className="container">
                <ModalDatiFatturazione isVisible={modalVisible} hideFunc={() => this.closeModal()} data={datiFatturazione}/>
                <div className="row d-flex justify-content-between align-items-center my-3 p-4 table_top_rounded">
                    <div className="col-4 color_white">
                        <span className="fw-bold">LISTA VENDITE</span>
                    </div>
                    <div className="col-4">
                        <Form.Control
                            type="text"
                            value={searchString}
                            onChange={(e: ChangeEvent<any>) => this.setState({ searchString: e.target.value, currentPage: 1 })}
                            required
                        />
                    </div>
                </div>
                <div className="table_bottom_rounded">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">DESCRIZIONE</th>
                                <th scope="col">DATA</th>
                                <th scope="col">EMAIL</th>
                                <th scope="col">IMPORTO</th>
                                <th scope="col" className="text-center">
                                    <div className="d-none d-md-flex justify-content-between align-items-center">
                                        <div>
                                            <span>DETTAGLI</span>
                                        </div>
                                        <div>
                                            <span>FATTURATO</span>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedFilteredData.map((e: ReportVendideDto, i: number) => (
                                <tr key={i}>
                                    <td>{e.descrizioneVendita ?? '-'}</td>
                                    <td>{format(new Date(e.dataVendita),'dd/MM/yyy') ?? '-'}</td>
                                    <td>{e.userEmail ?? '-'}</td>
                                    <td>{e.importo+'€' ?? '-'}</td>
                                    <td className="text-center">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="ms-3 color_primary">
                                                <FaEye style={{ cursor: 'pointer' }} size={30} onClick={() => this.openModal(e.id)} />
                                            </div>
                                            <div className="color_primary">
                                                <Button className={e.fatturato ? "btn btn-success w-100" : "btn btn-danger w-100"} onClick={() => { this.dispatch(e.id) }}>{e.fatturato ? 'SI' : 'NO'}</Button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="d-flex justify-content-center">
                        <Button className="numberCircle btn_arrow" onClick={() => this.setState({ currentPage: currentPage - 1 })} disabled={currentPage === 1}>{'<'}</Button>
                        <PaginationIndex currentPage={currentPage} totalPage={totalPage} setCurrentPageFunc={this.setCurrentPageByNum} />
                        <Button className="numberCircle btn_arrow" onClick={() => this.setState({ currentPage: currentPage + 1 })} disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage) || filteredData.length <= itemsPerPage}>{'>'}</Button>
                    </div>
                </div>
            </div>
        )
    }
}

ReportVendite.contextType = LoaderContext