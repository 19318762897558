import { notification } from "antd-notifications-messages";
import { ChangeEvent, Component, ContextType, ReactNode } from "react";
import { Form } from "react-bootstrap";
import { LoaderContext } from "../context";
import axios, { AxiosError } from "axios";
import { endpoint } from "../helpers";
import { RegisterModel } from "../dto/LoginModel";

interface IProps { }
interface IState {
    email: string,
    password: string,
    confermaPassword: string
}

export class Register extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            email: '',
            password: '',
            confermaPassword: ''
        };

        this.register = this.register.bind(this);
    }

    context!: ContextType<typeof LoaderContext>


    register() {
        const { email, password, confermaPassword } = this.state;

        if (!email) {
            notification({ message: 'Inserire indirizzo email', type: 'error' });
            return;
        }

        else if (!password) {
            notification({ message: 'Inserire password', type: 'error' });
            return;
        }

        else if ((password !== confermaPassword)) {
            notification({ message: 'Le due password non concidono', type: 'error' });
            return;
        }


        const { toggleLoader } = this.context
        const searchParams = new URLSearchParams(window.location.search)
        const returnUrl = searchParams.get('returnUrl') ?? '/';

        const model: RegisterModel =
        {
            email: email,
            password: password,
        }

        toggleLoader(true)
        axios.post(`${endpoint}/api/Auth/Register`, model, { withCredentials: true })
            .then(() => {
                notification({ message: 'Registrazione avvenuta con successo', type: 'success' });
                setTimeout(() => { window.location.href = returnUrl }, 1000);
            }).catch((error: AxiosError<string>) => {
                notification({ message: error.response?.data ?? 'Errore', type: 'error' })
            }).finally(() => {
                toggleLoader(false)
            })
    }

    render(): ReactNode {
        
        return (
            <div className="container p-4 d-flex justify-content-center">
                <div className="box-semi-trasparent center-custom">
                    <h2>Registra un nuovo account</h2>

                    <div className="row d-flex justify-content-center">
                        <div className="mt-3"></div>
                        <p className="p-0 m-0 ">E-Mail</p>
                        <Form.Control
                            type="text" style={{ width: 270, height: 40 }}
                            onChange={(e: ChangeEvent<any>) => this.setState({ email: e.target.value })}
                        />
                    </div>

                    <div className="row d-flex justify-content-center p-0 m-0">
                        <div className="mt-1"></div>
                        <p className="p-0 m-0 ">Password</p>
                        <Form.Control
                            type="password" style={{ width: 270, height: 40 }}
                            onChange={(e: ChangeEvent<any>) => this.setState({ password: e.target.value })}
                        />
                    </div>

                    <div className="row d-flex justify-content-center p-0 m-0">
                        <div className="mt-1"></div>
                        <p className="p-0 m-0 ">Conferma password</p>
                        <Form.Control
                            type="password" style={{ width: 270, height: 40 }}
                            onChange={(e: ChangeEvent<any>) => this.setState({ confermaPassword: e.target.value })}
                        />
                    </div>

                    <div className="row d-flex justify-content-center">
                        <button className="btn btn-custom mt-4" style={{ width: 270, height: 40 }}
                            onClick={this.register}
                        >
                            REGISTRATI
                        </button>
                    </div>

                    <div className="row d-flex justify-content-center mt-5">
                        <h6>oppure</h6>
                    </div>

                    <div className="row d-flex justify-content-center mt-4">
                        <a href="/passwordRecover">
                            <h5>Password dimenticata?</h5>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

Register.contextType = LoaderContext