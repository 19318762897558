import { Component, ContextType, ReactNode } from "react";
import { CardLezione } from "../components/CardLezione";
import { LoaderContext } from "../context";
import axios, { AxiosError } from "axios";
import { endpoint } from "../helpers";
import { VideoCorso } from "../dto/VideoCorso";
import { notification } from "antd-notifications-messages";
import { Lezione } from "../dto/Lezione";

interface IProps { }

interface IState {
    videoCorso: VideoCorso | null
}

export class CourseDetail extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            videoCorso: null
        };
        this.getCourse = this.getCourse.bind(this);
    }

    context!: ContextType<typeof LoaderContext>



    componentDidMount(): void {
        this.getCourse();
    }

    getCourse() {
        const searchParams = new URLSearchParams(window.location.search)
        const { toggleLoader } = this.context

        toggleLoader(true)
        axios.get<VideoCorso>(`${endpoint}/api/VideoCorsi/Get?IdVideoCorso=${searchParams.get('id')}`, { withCredentials: true })
            .then(({ data }) => {
                this.setState({ videoCorso: data })
            }).catch((error: AxiosError<string>) => {
                notification({ message: error.response?.data ?? 'Errore', type: 'error' })
            }).finally(() => {
                toggleLoader(false)
            })
    }

    render(): ReactNode {

        const { videoCorso } = this.state;

        return (
            <div className="container p-4">
                <div className="row">

                    <div className="col-12 col-md-8">
                        <h4>{videoCorso?.titolo}</h4>
                        <h6>{videoCorso?.descrizione}</h6>
                        <h5><i>Acquistando tutti e 4 i capitoli verrà applicato uno sconto del 25%</i></h5>
                    </div>

                    <div className="p-3 col-12 col-md-4">
                        <div className=" card-lezione">
                            <div className="row">
                                <div className="col-4">
                                    <div style={{ width: '100%', height: '100px', backgroundImage: `url('https://corsoprofessionalediegotattoo.com/assets/kit-corso-professionale-by-diego-tattoo.jpg')`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }} />
                                </div>
                                <div className="col-8 p-3 pt-4">
                                    <h5>Kit dei materiali utilizzati</h5>
                                    <a style={{fontSize:13}} href="https://electricdormouse.it/it/kit-completi/7501-kit-corso-professionale-by-diego-tattoo.html">Clicca qui per andare allo shop</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container p-0">
                    <div className="row">
                        {videoCorso?.lezioni.map((l: Lezione, i: number) => (
                            <div className="col-lg-3 col-md-6" key={i}>
                                <CardLezione
                                    lezione={l}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

CourseDetail.contextType = LoaderContext