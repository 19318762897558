import { ChangeEvent, Component, ReactNode } from "react";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

interface IProps { }
interface IState {
    currentIndex: number;
}

const images = [
    "https://corsoprofessionalediegotattoo.com/assets/tattoo/tattoo2.jpg",
    "https://corsoprofessionalediegotattoo.com/assets/tattoo/tattoo3.jpg",
    "https://corsoprofessionalediegotattoo.com/assets/tattoo/tattoo4.jpg",
    "https://corsoprofessionalediegotattoo.com/assets/tattoo/tattoo5.jpg",
    "https://corsoprofessionalediegotattoo.com/assets/tattoo/tattoo6.jpg",
    "https://corsoprofessionalediegotattoo.com/assets/tattoo/tattoo7.jpg",
    "https://corsoprofessionalediegotattoo.com/assets/tattoo/tattoo8.jpg",
    "https://corsoprofessionalediegotattoo.com/assets/tattoo/tattoo1.jpg",
];

export class ImageSlider extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            currentIndex: 0
        };

    }

    handleChange = (currentIndex: number) => {
        this.setState({ currentIndex: currentIndex });
    };

    render(): ReactNode {

        const { currentIndex } = this.state;

        return (
            <>
                <div className="slider">

                    <Slide
                        arrows={true}
                        onChange={this.handleChange}                                       
                    >
                        {images.map((image, index) => (

                            <div className="each-slide-effect" key={index}>
                                <div style={{ 'backgroundImage': `url(${image})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'auto' }}>
                                </div>
                            </div>
                        ))}

                    </Slide>

                    <div className="container d-flex justify-content-center">
                        {images.map((image, index) => (
                            <span key={index}
                                className={currentIndex === index ? "punto-slider-active" : "punto-slider"}
                                onClick={(e: ChangeEvent<any>) => this.setState({ currentIndex: index })}
                            ></span>
                        ))}
                    </div>
                </div>
            </>
        )
    }
}


