import { ChangeEvent, Component, ContextType, ReactNode } from "react";
import { LoaderContext } from "../context";
import { Form } from "react-bootstrap";
import { notification } from "antd-notifications-messages";
import axios, { AxiosError } from "axios";
import { endpoint } from "../helpers";

interface IProps { }
interface IState {
    email: string
}

export class PasswordRecover extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            email: ''
        };
        this.conferma = this.conferma.bind(this);
    }

    context!: ContextType<typeof LoaderContext>

    conferma() {
        const { email } = this.state;
        const { toggleLoader } = this.context

        if (!email) {
            notification({ message: 'Inserire uhn indirizzo e-mail', type: 'error' });
            return;
        }

        toggleLoader(true)
        axios.post(`${endpoint}/api/Auth/ResetPassword?email=${email}`, { withCredentials: true })
            .then(() => {
                notification({ message: `Se l'indirizzo mail è corretto riceverai un link per settare una nuova password`, type: 'success' })
                setTimeout(() => { window.location.href = './home' }, 2000);
            }).catch((error: AxiosError<string>) => {
                notification({ message: error.response?.data ?? 'Errore', type: 'error' })
            }).finally(() => {
                toggleLoader(false)
            })

    }

    render(): ReactNode {

        return (
            <div className="container p-4 d-flex justify-content-center">
                <div className="box-semi-trasparent center-custom">
                    <h2>Recupero password</h2>

                    <div className="row d-flex justify-content-center mt-5">
                        <h5>Inserisci la tua e-mail per procedere con il recupero della password</h5>
                    </div>


                    <div className="row mt-3 d-flex justify-content-center">
                        <p className="p-0 m-0 ">E-Mail</p>
                        <Form.Control
                            type="text"
                            style={{ width: 270, height: 40 }}
                            onChange={(e: ChangeEvent<any>) => this.setState({ email: e.target.value })}
                        />
                    </div>


                    <div className="row d-flex justify-content-center">
                        <button className="btn btn-custom mt-4" style={{ width: 270, height: 40 }}
                            onClick={this.conferma}
                        >
                            CONFERMA
                        </button>
                    </div>

                    <div className="row d-flex justify-content-center mt-5">
                        <h6>oppure</h6>
                    </div>

                    <div className="row d-flex justify-content-center mt-4">
                        <a href="./register">
                            <h5>Registra un nuovo account</h5>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

PasswordRecover.contextType = LoaderContext