import { Component, ContextType, ReactNode } from "react"
import { UserContext } from "../../context"
import { Home, Login, NotFound } from "../../pages/index"

export class AllPage extends Component {

    context!: ContextType<typeof UserContext>

    render(): ReactNode {
        const { isLogged } = this.context
        return (
            <>

            <Home />
 
            </>
        )
    }
}

AllPage.contextType = UserContext