import { Component, ReactNode } from "react";
import { GiPositionMarker } from "react-icons/gi";
import { IoIosCall } from "react-icons/io";
import { MdMailOutline } from "react-icons/md";

interface IProps { }
interface IState { }

export class Footer extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render(): ReactNode {

        return (
            <div className="bg-nav-footer footer">
                <div className="row">
                    <div className="col-sm-0 col-md-3"></div>
                    <div className="col col-sm-4 col-md-2">
                        <div className="row" >
                            <div className="col-2 p-0">
                                <span><GiPositionMarker size={26} /></span>
                            </div>
                            <div className="col-10 p-0">
                                <span>Corso Francia 123</span>
                                <p>Torino TO</p>
                            </div>
                        </div>
                    </div>
                    <div className="col col-sm-4 col-md-2">
                        <div className="row">
                            <span>
                                <IoIosCall size={16} />
                                3683464499
                            </span>
                            {/* <p>
                                <MdMailOutline size={16} />
                                diego@tatto.com
                            </p> */}
                        </div>
                    </div>
                    <div className="col col-sm-4 col-md-2">
                        <div className="row">
                            <a href="./policyPrivacy">Informativa privacy</a>
                            <p>P.I. 08506460016</p>
                        </div>
                    </div>
                    <div className="col-sm-0 col-md-3"></div>
                </div>
            </div>
        )
    }
}