import { Component, ContextType, ReactNode } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { UserContext } from "../../context";
import axios, { AxiosError } from "axios";
import { endpoint } from "../../helpers";
import { notification } from "antd-notifications-messages";
import { wait } from "@testing-library/user-event/dist/utils";

interface IProps { }
interface IState {
}

export class NavBar extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
    };

    this.logOut = this.logOut.bind(this);
  }

  context!: ContextType<typeof UserContext>


  logOut() {
    axios.get(`${endpoint}/api/Auth/LogOut`, { withCredentials: true })
      .then(() => {
        window.location.href = `/`
      }).catch((error: AxiosError<string>) => {
        notification({ message: error.response?.data ?? 'Errore', type: 'error' })
      }).finally(() => {
      })
  }

  render(): ReactNode {
    const { isLogged, admin } = this.context

    return (
      <div className="container">

        <Navbar expand="lg" className="bg-nav-footer nav-curva row">


          <div className="col-2 col-md-4 d-flex justify-content-center p-0"
            onClick={() => window.location.href = '/'}

          >
            <img id="logo" src="https://corsoprofessionalediegotattoo.com/assets/logo.png" alt="logo" style={{ width: 50, marginLeft: 20 }} />
          </div>

          <div className="col-7 col-md-4 p-0 d-flex justify-content-center">
            <div className="titolo"><a className="no-underline" href="/" > Diego Tattoo</a>

              <div className="hiddenn-maggione-990 ">
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link className="nav-text" href="/home">Home</Nav.Link>

                    {(!isLogged ? (
                      <>
                        <Nav.Link className="nav-text titolo" href="/login">Accedi</Nav.Link>
                        <Nav.Link className="nav-text titolo" href="/register">Registrati</Nav.Link>
                      </>
                    ) : (
                      <>
                        <Nav.Link className="nav-text titolo" onClick={this.logOut}>Esci</Nav.Link>
                        <Nav.Link className="nav-text titolo" href="./carrello">Carrello</Nav.Link>
                        {admin && (
                          <Nav.Link className="nav-text titolo" href="./reportVendite">Report vendite</Nav.Link>
                        )}
                      </>
                    ))}

                  </Nav>
                </Navbar.Collapse>
              </div>
            </div>





          </div>

          <div className="col-2 col-md-4 p-0">
            <Navbar.Toggle id="navbtn" aria-controls="basic-navbar-nav" />

            <div className="hiddenn-minore-990  nav-link-custom">
              <Nav className="me-auto">
                <Nav.Link className="nav-text" href="/home">Home</Nav.Link>
                {(!isLogged ? (
                  <>
                    <Nav.Link className="nav-text " href="/login">Accedi</Nav.Link>
                    <Nav.Link className="nav-text " href="/register">Registrati</Nav.Link>
                  </>
                ) : (
                  <>
                    <Nav.Link className="nav-text " onClick={this.logOut}>Esci</Nav.Link>
                    <Nav.Link className="nav-text " href="./carrello">Carrello</Nav.Link>
                    {admin && (
                      <Nav.Link className="nav-text" href="./reportVendite">Report vendite</Nav.Link>
                    )}

                  </>
                ))}
              </Nav>
            </div>
          </div>


        </Navbar>
      </div>

    )
  }
}

NavBar.contextType = UserContext