import { Component, ContextType, ReactNode } from "react"
import { LoaderContext } from "../../context"
import Spinner from 'react-bootstrap/Spinner';

interface IProps { }
interface IState {
    dots: number;
}

export class Loader extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            dots: 1,
        };
    }

    context!: ContextType<typeof LoaderContext>
    timerId!: NodeJS.Timeout;

    componentDidMount() {
        this.timerId = setInterval(() => {
            this.setState(prevState => ({
                dots: prevState.dots % 3 + 1,
            }));
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
    }

    render(): ReactNode {
        const { isLoading  } = this.context
        return (
            <div style={{ display: isLoading ? 'block' : 'none' }} className="vw-100 h-100 position-absolute">
                <div className="w-100 h-100 d-flex justify-content-center align-items-center fixed-top bg-loader">
                    {/* <Spinner animation="border" variant="danger" /> */}
                    <img height={100} src="https://corsoprofessionalediegotattoo.com/assets/gifloader.gif" alt="" />
                </div>
            </div>
        )
    }
}
Loader.contextType = LoaderContext