import { Component, ReactNode } from "react";

interface IProps {
    currentPage: number,
    totalPage: number,
    setCurrentPageFunc: any
}
interface IState { 
}

export class PaginationIndex extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
        }
        this.changePage = this.changePage.bind(this);
    }

    changePage(page: number): void {
        const { totalPage, setCurrentPageFunc } = this.props;

        let totalPageRounded = Math.ceil(totalPage);
        if (page > 0 && page <= totalPageRounded) {
            setCurrentPageFunc(page)
        }
    }


    render(): ReactNode {

        const { currentPage, totalPage } = this.props;

        let totalPageRounded = Math.ceil(totalPage);

        return (
            <>
                <span className="numberCircle" onClick={() => this.changePage(currentPage - 2)}> {currentPage - 2 > 0 ? currentPage - 2 : ''} </span>
                <span className="numberCircle" onClick={() => this.changePage(currentPage - 1)}> {currentPage - 1 > 0 ? currentPage - 1 : ''} </span>
                <span className="numberCircle active"> {currentPage}</span>
                <span className="numberCircle" onClick={() => this.changePage(currentPage + 1)}> {currentPage + 1 <= totalPageRounded ? currentPage + 1 : ''} </span>
                <span className="numberCircle" onClick={() => this.changePage(currentPage + 2)}> {currentPage + 2 <= totalPageRounded ? currentPage + 2 : ''} </span>
            </>
        )
    }
}