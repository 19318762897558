import { Component, ReactNode } from "react";

interface IProps { }
interface IState { }

export class PolicyPrivacy extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render(): ReactNode {

        return (
            <div className="container p-4 d-flex justify-content-center">
                <div className="box-semi-trasparent">

                    <h3>Informativa sulla Privacy</h3>

                    <p>La presente Informativa sulla Privacy descrive come corsoprofessionalediegotattoo.com raccoglie, utilizza e protegge le informazioni personali degli utenti che visitano e utilizzano il nostro sito web.</p>

                    <h3>Raccolta delle informazioni</h3>

                    <p>Quando gli utenti visitano il nostro sito web, potremmo raccogliere informazioni personali come nome, indirizzo email e dati di pagamento quando effettuano un acquisto. Utilizziamo tali informazioni solo per elaborare gli ordini e per comunicare con gli utenti riguardo ai loro acquisti.</p>

                    <h3>Utilizzo delle informazioni</h3>

                    <p>Le informazioni personali degli utenti sono utilizzate esclusivamente per le seguenti finalità:</p>

                    <ol>
                        <li>Elaborazione degli ordini e fornire i servizi acquistati.</li>
                        <li>Comunicazioni relative agli ordini, agli aggiornamenti sui prodotti o alle offerte speciali.</li>
                        <li>Mantenimento della sicurezza e della protezione del nostro sito web e dei nostri utenti.</li>
                        <li>Adempimento agli obblighi legali e normativi.</li>
                    </ol>

                    <h3>Protezione delle informazioni</h3>

                    <p>Ci impegniamo a proteggere le informazioni personali degli utenti e adottiamo misure di sicurezza adeguate per proteggerle da accesso non autorizzato, perdita, uso improprio o divulgazione.</p>

                    <h3>Divulgazione delle informazioni a terzi</h3>

                    <p>Non condividiamo, vendiamo o trasferiamo le informazioni personali degli utenti a terzi, tranne per quanto necessario per fornire i servizi richiesti dagli utenti stessi (ad esempio, l'elaborazione dei pagamenti tramite un processore di pagamento sicuro).</p>

                    <h3>Cookie</h3>

                    <p>Il nostro sito web può utilizzare cookie per migliorare l'esperienza degli utenti. Gli utenti possono scegliere di accettare o rifiutare i cookie attraverso le impostazioni del proprio browser.</p>

                    <h3>Modifiche all'Informativa sulla Privacy</h3>

                    <p>Ci riserviamo il diritto di apportare modifiche a questa Informativa sulla Privacy in qualsiasi momento. Gli utenti verranno informati tramite notifica sul nostro sito web delle eventuali modifiche.</p>

                    <h3>Accettazione</h3>

                    <p>Utilizzando il nostro sito web, gli utenti accettano e acconsentono alla nostra Informativa sulla Privacy e alle pratiche descritte in essa.</p>

                    <p>Ultimo aggiornamento: 06/02/2024.</p>


                </div>
            </div>
        )
    }
}