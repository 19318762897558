import { Component, ContextType, ReactNode } from "react";
import { LoaderContext } from "../context";


interface IProps { }

interface IState {

}

export class CheckOutResult extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
        }
    }

    context!: ContextType<typeof LoaderContext>

    componentDidMount(): void {
        setTimeout(() => { window.location.href = './home' }, 3000);
    }

    render(): ReactNode {

        const searchParams = new URLSearchParams(window.location.search)
        const success = searchParams.get('success');

        return (

            <div className="p-4">
                <div className={success?.toLowerCase() === 'true' ? "container p-4 card-success" : "container p-4 card-cancel"}>
                    <div className="row d-flex justify-content-center text-center">
                        {(success?.toLowerCase() === 'true' ?
                            (
                                <>
                                    <h5>Grazie il pagamento è andato a buon fine</h5>
                                    <h5>Adesso sarà possibile procedere con la fruizione degli articoli acquistati</h5>
                                </>
                            )
                            :
                            (

                                <>
                                    <h5>Il pagamento non è andato a buon fine</h5>
                                </>
                            )
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

CheckOutResult.contextType = LoaderContext