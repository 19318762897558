import { Component, ContextType, ReactNode } from "react"

import { LoaderContext } from "../../context"
import { Loader } from "../index"
import { AllRoutes } from "../../pages/base/AllRoutes"
import CookieBarner from "../../pages/CookieBarner";

interface IProps { }
interface IState {
}

export class AppContent extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {

        };
    }

    context!: ContextType<typeof LoaderContext>
    
    render(): ReactNode {
        const { isLoading } = this.context
        return (
            <div>
                {isLoading && (
                    <Loader />
                )}

                <CookieBarner></CookieBarner>

                <div style={{ opacity: isLoading ? 0 : 1 }}>
                    <AllRoutes />
                </div>
            </div>
        )
    }
}

AppContent.contextType = LoaderContext