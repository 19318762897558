import { ChangeEvent, Component, ContextType, ReactNode } from "react";
import { LoaderContext } from "../context";
import axios, { AxiosError } from "axios";
import { endpoint } from "../helpers";
import { notification } from "antd-notifications-messages";
import { Lezione } from "../dto/Lezione";
import { CarrelloDto } from "../dto/Carrello";
import { VideoCorso } from "../dto/VideoCorso";
import { CarrelloElemento } from "../components";
import { StripeUrlResponse } from "../dto/StripeUrl";
import { DatiFatturazioneDto } from "../dto/DatiFatturazione";
import { Form } from "react-bootstrap";


interface IProps { }

interface IState {
    carrello: CarrelloDto,
    datiFatturazione: DatiFatturazioneDto,
    accettatoTermini: boolean
}

export class Carrello extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            carrello: {
                id: -1,
                prezzoTotale: '',
                prezzoScontato: '',
                carrelloElemento: []
            },
            datiFatturazione: {
                id: '',
                nome: '',
                cognome: '',
                indirizzo: '',
                citta: '',
                provincia: '',
                cap: '',
                cf_Piva: ''
            },
            accettatoTermini: false
        };

        this.getCart = this.getCart.bind(this);
        this.getDatiFatturazione = this.getDatiFatturazione.bind(this);
        this.estraiVideoCorsi = this.estraiVideoCorsi.bind(this);
        this.estraiLezioni = this.estraiLezioni.bind(this);
        this.removeCart = this.removeCart.bind(this);
        this.checkout = this.checkout.bind(this);
        this.salvarDatiFatturazione = this.salvarDatiFatturazione.bind(this);
    }

    context!: ContextType<typeof LoaderContext>

    componentDidMount(): void {
        this.getCart();
    }

    getCart() {
        const { toggleLoader } = this.context

        toggleLoader(true)
        axios.get<CarrelloDto>(`${endpoint}/api/Carrello/Get`, { withCredentials: true })
            .then(({ data }) => {
                this.setState({ carrello: data })

                if (data.carrelloElemento !== null && data.carrelloElemento.length > 0)
                    this.getDatiFatturazione()

            }).catch((error: AxiosError<string>) => {
                notification({ message: error.response?.data ?? 'Errore', type: 'error' })
            }).finally(() => {
                toggleLoader(false)
            })
    }

    getDatiFatturazione() {
        const { toggleLoader } = this.context

        toggleLoader(true)
        axios.get<DatiFatturazioneDto>(`${endpoint}/api/Fatturazione/Get`, { withCredentials: true })
            .then(({ data }) => {
                this.setState({ datiFatturazione: data })
                console.log(data)
            }).catch((error: AxiosError<string>) => {
                notification({ message: error.response?.data ?? 'Errore', type: 'error' })
            }).finally(() => {
                toggleLoader(false)
            })
    }

    removeCart(videoCorso: boolean, id: number): void {

        const { toggleLoader } = this.context;
        const paramQueryString = !videoCorso ? `IdLezione=${id}` : `IdVideoCorso=${id}`

        toggleLoader(true)
        axios.get(`${endpoint}/api/Carrello/Rimuovi?${paramQueryString}`, { withCredentials: true })
            .then(() => {
                notification({ message: 'Rimosso dal carrello', type: 'success' });
                this.getCart();
            }).catch((error: AxiosError<string>) => {
                if (error.response?.status === 401) {
                    window.location.href = `/Login?returnUrl=/carrello`
                } else {
                    notification({ message: error.response?.data ?? 'Errore', type: 'error' })
                }
            }).finally(() => {
                toggleLoader(false)
            })
    }

    salvarDatiFatturazione(): void {

        const { toggleLoader } = this.context;
        const { datiFatturazione } = this.state

        toggleLoader(true)
        axios.post(`${endpoint}/api/Fatturazione/Save/`, datiFatturazione, { withCredentials: true })
            .then(() => {
                this.checkout();
            }).catch((error: AxiosError<string>) => {
                notification({ message: error.response?.data ?? 'Errore', type: 'error' })
            }).finally(() => {
                toggleLoader(false)
            })
    }

    checkout(): void {
        const { toggleLoader } = this.context;
        const { accettatoTermini } = this.state
        if (!accettatoTermini) {
            notification({ message: 'Bisogna accettare i nostri termini e condizioni prima di procedere', type: 'warning' })
            return;
        }
        toggleLoader(true)
        axios.get<StripeUrlResponse>(`${endpoint}/api/Carrello/Paga/`, { withCredentials: true })
            .then(({ data }) => {
                window.location.href = data.url
            }).catch((error: AxiosError<string>) => {
                notification({ message: error.response?.data ?? 'Errore', type: 'error' })
            }).finally(() => {
                toggleLoader(false)
            })
    }


    estraiVideoCorsi(carrelloDto: CarrelloDto): VideoCorso[] {
        const { carrelloElemento } = carrelloDto;
        const lezioni: VideoCorso[] = [];

        if (carrelloElemento) {
            carrelloElemento.forEach(elemento => {
                if (elemento.videoCorso) {
                    lezioni.push(elemento.videoCorso);
                }
            });
        }

        return lezioni;
    }

    estraiLezioni(carrelloDto: CarrelloDto): Lezione[] {
        const { carrelloElemento } = carrelloDto;
        const lezioni: Lezione[] = [];

        if (carrelloElemento) {
            carrelloElemento.forEach(elemento => {
                if (elemento.lezione) {
                    lezioni.push(elemento.lezione);
                }
            });
        }

        return lezioni;
    }

    render(): ReactNode {

        const { carrello, accettatoTermini, datiFatturazione } = this.state;
        const videoCorsi: VideoCorso[] = this.estraiVideoCorsi(carrello);
        const lezioni: Lezione[] = this.estraiLezioni(carrello);
        const totArticoli = videoCorsi.length + lezioni.length;
        const sconto = carrello.prezzoTotale !== carrello.prezzoScontato;

        return (
            <>
                <div className="container p-4" style={{ maxWidth: 500 }}>
                    <div className="card-carrello">
                        {totArticoli === 1 ?
                            (<h4>{totArticoli} Articolo nel carrello</h4>)
                            :
                            ((<h4>{totArticoli} Articoli nel carrello</h4>))
                        }
                        {sconto ? (
                            <div className="row">
                                <div className="col-2 mt-1">
                                    <p>Totale:</p>
                                </div>
                                <div className="col-10">
                                    <h2><s>{carrello.prezzoTotale}€</s> {carrello.prezzoScontato}€</h2>
                                </div>
                            </div>
                        ) : (
                            <div className="row">
                                <div className="col-2 mt-1">
                                    <p>Totale:</p>
                                </div>
                                <div className="col-10">
                                    <h2>{carrello.prezzoTotale}€</h2>
                                </div>
                            </div>
                        )}
                        <div className="separator"></div>


                        {videoCorsi.map((v: VideoCorso, i: number) => (
                            <CarrelloElemento
                                key={i + 1000}
                                lezione={null}
                                videoCorso={v}
                                removeChartFunc={this.removeCart}
                            />
                        ))}
                        {lezioni.map((l: Lezione, i: number) => (
                            <CarrelloElemento
                                key={i + 100}
                                lezione={l}
                                videoCorso={null}
                                removeChartFunc={this.removeCart}
                            />
                        ))}

                        {(totArticoli > 0 &&
                            <div className="col-12">

                                <div className="container mb-3 mt-3">
                                    <Form.Check
                                        checked={accettatoTermini}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ accettatoTermini: e.target.checked })}
                                        type="checkbox"
                                        label="Accetto i termini e condizioni di vendita"
                                        style={{ fontSize: '14px' }}
                                    />
                                </div>
                                <button className="btn btn-custom w-100 p-0" style={{ minHeight: 30, fontSize: 10, zIndex: 2 }}
                                    onClick={this.salvarDatiFatturazione}
                                >
                                    PROCEDI CON IL PAGAMENTO
                                </button>
                            </div>
                        )}
                    </div>

                    {(totArticoli > 0 &&
                        <>
                            <div className="card-carrello">
                                <div className="container p-1">
                                    <div className="row text-center">
                                        <h5>Dati per la fatturazione</h5>
                                        <div className="separator"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <Form.Control
                                                type="text"
                                                placeholder="Nome"
                                                value={datiFatturazione.nome ?? ''}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ datiFatturazione: { ...datiFatturazione, nome: e.target.value } })}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <Form.Control
                                                type="text"
                                                placeholder="Cognome"
                                                value={datiFatturazione.cognome ?? ''}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ datiFatturazione: { ...datiFatturazione, cognome: e.target.value } })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <Form.Control
                                                type="text"
                                                placeholder="Indirizzo"
                                                value={datiFatturazione.indirizzo ?? ''}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ datiFatturazione: { ...datiFatturazione, indirizzo: e.target.value } })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-8">
                                            <Form.Control
                                                type="text"
                                                placeholder="Città"
                                                value={datiFatturazione.citta ?? ''}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ datiFatturazione: { ...datiFatturazione, citta: e.target.value } })}
                                            />
                                        </div>
                                        <div className="col-4">
                                            <Form.Control
                                                type="text"
                                                maxLength={2}
                                                placeholder="Provincia"
                                                value={datiFatturazione.provincia ?? ''}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ datiFatturazione: { ...datiFatturazione, provincia: e.target.value } })}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-4">
                                            <Form.Control
                                                type="number"
                                                placeholder="CAP"
                                                maxLength={5}
                                                value={datiFatturazione.cap ?? ''}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                    const value = e.target.value;
                                                    if (value === "" || Number.isInteger(Number(value))) {
                                                        this.setState({ datiFatturazione: { ...this.state.datiFatturazione, cap: value } });
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="col-8">
                                            <Form.Control
                                                type="text"
                                                placeholder="CF/P.IVA"
                                                value={datiFatturazione.cf_Piva ?? ''}
                                                onChange={(e: ChangeEvent<HTMLInputElement>) => this.setState({ datiFatturazione: { ...this.state.datiFatturazione, cf_Piva: e.target.value } })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-carrello">
                                <div className="row text-center">
                                    <h6>Termini e condizioni per l'acquisto di video digitali</h6>
                                </div>
                                <div style={{ fontSize: 12 }}>
                                    <ol>
                                        <li>
                                            <strong>Accettazione dei Termini:</strong> L'acquisto dei video digitali implica l'accettazione dei seguenti termini e condizioni. Si prega di leggere attentamente prima di procedere con il pagamento.
                                        </li>
                                        <li>
                                            <strong>Fruiibilità dei Video:</strong> I video digitali acquistati sono fruibili esclusivamente sulla piattaforma online attraverso la quale sono stati acquistati. Non è consentito scaricare i video su dispositivi esterni o copiarli in qualsiasi forma.
                                        </li>
                                        <li>
                                            <strong>Proprietà Intellettuale:</strong> Tutti i diritti di proprietà intellettuale relativi ai video, inclusi ma non limitati a copyright, marchi registrati e diritti d'autore, sono di proprietà esclusiva del titolare dei diritti. L'acquisto dei video non trasferisce alcun diritto di proprietà intellettuale all'acquirente.
                                        </li>
                                        <li>
                                            <strong>Utilizzo Personale:</strong> L'acquirente è autorizzato a fruire dei video per uso personale e non commerciale. È vietato distribuire, condividere o riprodurre i video in qualsiasi forma senza il consenso esplicito del titolare dei diritti.
                                        </li>
                                        <li>
                                            <strong>Nessun Rimborso:</strong> Una volta completato l'acquisto, non sono previsti rimborsi per i video digitali. Si prega di valutare attentamente la decisione di acquisto prima di confermare il pagamento.
                                        </li>
                                        <li>
                                            <strong>Limitazione di Responsabilità:</strong> Il venditore non sarà responsabile per eventuali danni diretti, indiretti, speciali, consequenziali o punitivi derivanti dall'uso o dall'incapacità di utilizzare i video digitali.
                                        </li>
                                        <li>
                                            <strong>Modifiche ai Termini:</strong> Il venditore si riserva il diritto di modificare i presenti termini e condizioni in qualsiasi momento senza preavviso. Le modifiche saranno efficaci immediatamente dopo la pubblicazione sulla piattaforma online.
                                        </li>
                                        <li>
                                            <strong>Legge Applicabile:</strong> Questi termini e condizioni sono disciplinati e interpretati in conformità con le leggi dello Stato in cui è registrata la società venditrice.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </>
                    )}
                </div>


            </>
        )
    }
}

Carrello.contextType = LoaderContext