import { Component, ContextType, PropsWithChildren, ReactNode } from "react"
import axios, { AxiosError } from "axios"
import { notification } from "antd-notifications-messages"

import { LoaderContext, UserContext, UserDefaultType, UserDefaultValue } from ".."
import { endpoint } from "../../helpers"

interface IState {
    user: UserDefaultType
}

export class UserProvider extends Component<PropsWithChildren<any>, IState> {
    constructor(props: PropsWithChildren<any>) {
        super(props)
        this.state = {
            user: UserDefaultValue,
        }
    }

    context!: ContextType<typeof LoaderContext>

    componentDidMount(): void {
        const { toggleLoader } = this.context

        toggleLoader(true)
        axios.get<UserDefaultType>(`${endpoint}/api/Auth/GetCurrentUser`, { withCredentials: true })
            .then(({ data }) => {
                this.setState({ user: data })
            }).catch((error: AxiosError<string>) => {
                notification({ message: error.response?.data ?? 'Errore', type: 'error' })
            }).finally(() => {
                toggleLoader(false)
            })
    }

    render(): ReactNode {
        const { user } = this.state
        return (
            <UserContext.Provider value={user}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}

UserProvider.contextType = LoaderContext