import { Component, ContextType, ReactNode } from "react"
import { UserContext } from "../../context"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { AllPage, Footer, NavBar } from "../../components/index"
import { Login } from "../Login"
import { Register } from "../Register"
import { PasswordRecover } from "../PasswordRecover"
import { Home } from "../Home"
import { CourseDetail } from "../CourseDetail"
import { LessonDeail } from "../LessonDeail"
import { Carrello } from "../Carrello"
import { CheckOutResult } from "../CheckOutResult"
import { PolicyPrivacy } from "../PolicyPrivacy"
import { PasswordSetNew } from "../PasswordSetNew"
import { ReportVendite } from "../ReportVendite"





type RouteObj = {
    path: string
    element: JSX.Element
    restrictedRole?: string | null
}

export class AllRoutes extends Component {

    context!: ContextType<typeof UserContext>

    render(): ReactNode {

        const { isLogged } = this.context

        const routes: RouteObj[] = [
            { path: '/home', element: <Home /> },
            { path: '/login', element: <Login /> },
            { path: '/register', element: <Register /> },
            { path: '/passwordRecover', element: <PasswordRecover /> },
            { path: '/passwordSetNew', element: <PasswordSetNew /> },
            { path: '/courseDetail', element: <CourseDetail /> },
            { path: '/lessonDetail', element: <LessonDeail /> },
            { path: '/carrello', element: <Carrello /> },
            { path: '/checkOutResult', element: <CheckOutResult /> },
            { path: '/policyPrivacy', element: <PolicyPrivacy /> },
            { path: '/reportVendite', element: <ReportVendite /> }
        ]

        return (
            <div id="Homepage">  

                <NavBar />               
                <div style={{ minHeight: '85vh' }}>
                    <BrowserRouter>
                        <Routes>
                            <>
                                {routes.map((e: RouteObj, i: number) => (
                                    <Route key={i} path={e.path} element={e.element} />
                                ))}
                                <Route path={'*'} element={<AllPage />} />
                            </>
                        </Routes>
                    </BrowserRouter>
                </div>
                <Footer />
            </div>
        )
    }
}

AllRoutes.contextType = UserContext