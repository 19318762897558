import { ChangeEvent, Component, ContextType, ReactNode } from "react";
import { FaFacebook, FaGoogle } from "react-icons/fa6";
import { LoaderContext } from "../context";
import axios, { AxiosError } from "axios";
import { endpoint } from "../helpers";
import { notification } from "antd-notifications-messages";
import { Form } from "react-bootstrap";
import { LoginModel } from "../dto/LoginModel";
import { text } from "node:stream/consumers";

interface IProps { }
interface IState {
    returnUrl: string
    email: string
    password: string
}

export class Login extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            returnUrl: '/',
            email: '',
            password: ''
        }

        this.accediconGoogle = this.accediconGoogle.bind(this);
        this.accediconFacebook = this.accediconFacebook.bind(this);
        this.accedi = this.accedi.bind(this);
    }

    context!: ContextType<typeof LoaderContext>

    componentDidMount(): void {
        const searchParams = new URLSearchParams(window.location.search);
        const returnUrlQueryString = searchParams.get('returnUrl');

        if (returnUrlQueryString !== null)
            this.setState({ returnUrl: returnUrlQueryString })
        else
            this.setState({ returnUrl: 'home' })
    }

    accediconGoogle() {
        const { returnUrl } = this.state
        window.location.href = `${endpoint}/api/Auth/LoginGoogle?returnUrl=${returnUrl}`
    }

    accediconFacebook() {
        const { returnUrl } = this.state
        window.location.href = `${endpoint}/api/Auth/LoginFacebook?returnUrl=${returnUrl}`
    }

    accedi() {
        const { toggleLoader } = this.context
        const { returnUrl, email, password } = this.state


        const model: LoginModel = {
            email: email,
            password: password
        }

        toggleLoader(true)
        axios.post(`${endpoint}/api/Auth/Login`, model, { withCredentials: true })
            .then(() => {
                window.location.href = returnUrl
            }).catch((error: AxiosError<string>) => {
                notification({ message: error.response?.data ?? 'Errore', type: 'error' })
            }).finally(() => {
                toggleLoader(false)
            })
    }

    render(): ReactNode {

        return (
            <div className="container p-4 d-flex justify-content-center">
                <div className="box-semi-trasparent center-custom">
                    <h2>Accedi al tuo account</h2>

                    <div className="row d-flex justify-content-center mt-4">
                        <button className="btn btn-custom" style={{ width: 270, height: 40 }}
                            onClick={this.accediconGoogle}>
                            <div className="row">
                                <div className="col-2">
                                    <FaGoogle style={{ marginRight: 10 }} size={25} />
                                </div>
                                <div className="col-10">
                                    ACCEDI CON GOOGLE
                                </div>
                            </div>
                        </button>
                    </div>

                    {/* <div className="row d-flex justify-content-center">
                        <button className="btn btn-custom mt-4" style={{ width: 270, height: 40 }}
                            onClick={this.accediconFacebook}>
                            <div className="row">
                                <div className="col-2">
                                    <FaFacebook style={{ marginRight: 10 }} size={25} />
                                </div>
                                <div className="col-10">
                                    <span className="">ACCEDI CON FACEBOOK</span>
                                </div>
                            </div>
                        </button>
                    </div> */}

                    <div className="row d-flex justify-content-center">
                        <div className="mt-3"></div>
                        <p className="p-0 m-0 ">E-Mail</p>
                        <Form.Control
                            type="text"
                            style={{ width: 270, height: 40 }}
                            onChange={(e: ChangeEvent<any>) => this.setState({ email: e.target.value })}
                        />
                    </div>

                    <div className="row d-flex justify-content-center p-0 m-0">
                        <div className="mt-1"></div>
                        <p className="p-0 m-0 ">Password</p>
                        <Form.Control
                            type="password"
                            style={{ width: 270, height: 40 }}
                            onChange={(e: ChangeEvent<any>) => this.setState({ password: e.target.value })}
                        />
                    </div>

                    <div className="row d-flex justify-content-center">
                        <button className="btn btn-custom mt-4" style={{ width: 270, height: 40 }}
                            onClick={this.accedi}
                        >
                            ACCEDI
                        </button>
                    </div>

                    <div className="row d-flex justify-content-center mt-4">
                        <a href="/passwordRecover">
                            <h5>Password dimenticata?</h5>
                        </a>
                    </div>

                    <div className="row d-flex justify-content-center mt-2">
                        <h6>oppure</h6>
                    </div>

                    <div className="row d-flex justify-content-center mt-4">
                        <h5>
                            Non hai ancora un account? <br />
                            <a href="/register">
                                clicca qui
                            </a>
                            {' '}per iscriverti
                        </h5>
                    </div>

                </div>
            </div>
        )
    }
}


Login.contextType = LoaderContext