import { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ReportVendideDto } from '../dto/ReportVendideDto';
import { DatiFatturazioneDto } from '../dto/DatiFatturazione';

type IProps = {
    isVisible: boolean
    data: DatiFatturazioneDto | null
    hideFunc: () => void
}

type IState = {
}

export class ModalDatiFatturazione extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            data: null
        };
    }

    render() {
        const { isVisible, hideFunc, data } = this.props;


        return (
            <Modal style={{ color: 'black' }} show={isVisible} onHide={() => hideFunc()} centered>
                <Modal.Header>
                    <Modal.Title>Dati di fatturazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='row'>
                        <div className='col-4 d-flex justify-content-end'>
                            Nome:
                        </div>
                        <div className='col-8'>
                            {data?.nome}

                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-4 d-flex justify-content-end'>
                            Cognome:
                        </div>
                        <div className='col-8'>
                            {data?.cognome}

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4 d-flex justify-content-end'>
                            Indirizzo:
                        </div>
                        <div className='col-8'>
                            {data?.indirizzo}

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4 d-flex justify-content-end'>
                            Città e provincia
                        </div>
                        <div className='col-8'>
                            {data?.citta} {' '} {data?.provincia}

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4 d-flex justify-content-end'>
                            Cap
                        </div>
                        <div className='col-8'>
                            {data?.cap}

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4 d-flex justify-content-end'>
                            CF / PIva
                        </div>
                        <div className='col-8'>
                            {data?.cf_Piva}

                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className='row'>
                        <div className='col-12'>
                            <Button className="btn_primary" style={{ width: '100%' }} onClick={() => hideFunc()}>
                                CHIUDI
                            </Button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal >
        );
    }
}
