import { Component, PropsWithChildren, ReactNode } from "react"
import { LoaderContext } from ".."


interface IState {
    isLoading: boolean
    message?: string | null
    percentage: number
}

export class LoaderProvider extends Component<PropsWithChildren<any>, IState> {
    constructor(props: PropsWithChildren<any>) {
        super(props)
        this.state = {
            isLoading: false,
            message: null,
            percentage: 0
        }

        this.toggleLoader = this.toggleLoader.bind(this)
    }

    toggleLoader(value: boolean, message?: string | null, percentage: number = 0) {
        if (value === false)
            this.setState({ isLoading: value, message: null, percentage })
        else if (value === true && message)
            this.setState({ isLoading: value, message, percentage })
        else
            this.setState({ isLoading: value, percentage })
    }

    render(): ReactNode {
        const { isLoading, message, percentage } = this.state
        return (
            <LoaderContext.Provider value={{ isLoading, message, percentage, toggleLoader: this.toggleLoader }}>
                {this.props.children}
            </LoaderContext.Provider>
        )
    }
}